<template>
  <div class="hello">
    <div class="title-tips-box">
      <h3 class="title-tips">UNI DATING APP LTD.</h3>
    </div>
    <div class="content-box">
      <!-- 顶部 -->
      <div class="header-content">
        <img class="header-img" src="../assets/img/header.jpg" alt="">
        <div class="header-mask"></div>
        <h1 class="header-title" >Uni Dating App Ltd. 感应宇宙科技</h1>
      </div>
      <!-- 关于我们 -->
      <div class="about-content">
        <h2 class="about-title">ABOUT US</h2>
        <div class="about-item-box">
          <img class="about-item-img" src="../assets/img/picture1.webp" alt="">
          <div class="about-item-info">
              <h4 class="about-item-info-title">我们的核心</h4>
              <p class="about-item-info-tips">成都感应宇宙科技有限责任公司是以提供计算机服务、开发手机软件应用为核心的科技公司。</p>
          </div>
        </div>

        <div class="about-item-box">
          
          <div class="about-item-info about-item-info-left">
              <h4 class="about-item-info-title">Sub App</h4>
              <p class="about-item-info-tips">Sub是一款受90、00后喜欢的亚文化社交平台，学生和白领居多，是一个为广大年轻人提供多样选择的活跃的社交平台。这是一个更专业、更纯粹、更活跃、更安全的小众同好社区。所有用户皆为真实注册用户，积极寻求小众风格，热爱生活，拒绝低俗。获得了稳定的良好用户留存，受到了大批年轻人的青睐。</p>
          </div>
          <img class="about-item-img about-item-img-right" src="../assets/img/picture2.webp" alt="">
        </div>

        <div class="about-item-box">
          <img class="about-item-img" src="../assets/img/picture3.webp" alt="">
          <div class="about-item-info">
              <h4 class="about-item-info-title">不断尝试</h4>
              <p class="about-item-info-tips">向右滑喜欢，向左滑无感，翻动探探推送的照片，寻找与你最近的缘分。如果你喜欢的人也喜欢你，恭喜，一见钟情！</p>
              <p class="about-item-info-tips">在探探，只有相互喜欢的人才可以开始聊天，眼缘不错，趣味相投，向陌生人尴尬的搭讪说再见吧。</p>
          </div>
        </div>
      </div>

      <!-- 订阅邮箱 -->
      <div class="subscribe-content" v-if="!subscribeSuccess">
        <h3 class="subscribe-title">Subscribe</h3>
        <div class="subscribe-input-box">
          <form class="subscribe-form" action="">
            <div class="subscribe-input-info">
              <div class="subscribe-input-label" :class="{'subscribe-input-label-highlight': !showInputPlaceholder}">Email</div>
              <input class="subscribe-input" v-model="emailValue" @focus="inputFocus()" @blur="inputblur(this)" type="text">
            </div>
            <div class="subscribe-error-tips" v-if="showInputError">Please enter a valid email address.</div>
          </form>
        </div>
        <div class="subscribe-input-btn" type="submit" @click="submitEmail()">SIGN UP</div>
      </div>

      <div class="subscribe-success" v-if="subscribeSuccess">
        <p class="subscribe-success-title">Great, now please verify your email</p>
        <p class="subscribe-success-info">To let us know it's really you and that you'd like to receive emails from us, please click the link in the confirmation email we just sent you. You can unsubscribe from these emails at any time.</p>
      </div>

      <!-- 联系我们 -->
      <div class="contact-content">
        <div class="contact-left">
          <div v-if="!contactSuccess">
            <h2 class="contact-left-title">CONTACT US 联系我们</h2>
            <h4 class="contact-left-white">Better yet, see us in person! 寻找最好的你。</h4>
            <p class="contact-left-gray">四川省成都市武侯区二环路南一段13号3层318号</p>
            <h4 class="contact-left-white">成都感应宇宙科技有限责任公司</h4>
            <p class="contact-left-gray">610041, Wuhou Qu, Chengdu Shi, Sichuan, China</p>
          </div>
          <img class="contact-success-img" v-if="contactSuccess" src="../assets/img/contact-success.png" alt="">
          <h4 class="contact-left-white">Hours</h4>
          <p class="contact-left-gray">Open today <span class="contact-left-red"> 09:00 am – 05:00 pm</span></p>
          <div class="contact-left-btn" @click="showContactWriteClick()" v-if="!contactSuccess">Drop us a line!</div>

          <!-- 填写相关数据 -->
          <div class="contact-write" v-if="showContactWrite">
            <h4 class="contact-title">Drop us a line!</h4>
            <div class="contact-inputbox">
              <div class="contact-input-label" :class="{'contact-input-label-highlight': !showNamePlaceholder}">Name</div>
              <input class="contact-input" v-model="contactNameValue" @focus="contactNameInputFocus()" @blur="contactNameInputblur(this)" type="text">
            </div>
            <div class="contact-inputbox" style="margin-bottom:0px;">
              <div class="contact-input-label" :class="{'contact-input-label-highlight': !showEmailPlaceholder}">Email</div>
              <input class="contact-input" v-model="contactEmailValue" @focus="contactEmailInputFocus()" @blur="contactEmailInputblur(this)" type="text">
            </div>
            <div class="contact-error-tips">{{showContactEmailError ? 'Please enter a valid email address.' : ''}}</div>

            <textarea class="contact-textarea" v-model="contactMessageValue" placeholder="Message" ></textarea>
            <p class="contact-left-gray" style="text-align: center;">This site is protected by reCAPTCHA and the Google <span class="contact-left-red">Privacy Policy</span> and <span class="contact-left-red">Terms of Service</span> apply.</p>
            <div class="contact-write-btn-box">
              <div class="contact-write-btn-send" @click="contactSendClick()">SEND</div>
              <div class="contact-write-btn-cancel" @click="contactCancelClick()">Cancel</div>
            </div>
            
          </div>
        </div>
        <div class="contact-right">
          <img class="contact-right-map" src="../assets/img/map.png" alt="">
        </div>
      </div>

      <div class="footer-content">
        Copyright © 2022 Uni Dating App Ltd. - All Rights Reserved.
        <img src="../assets/img/logo.jpg" style="width:14px;margin-right:2px"><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702003681">川公网安备 51010702003681号</a >&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/">蜀ICP备2022026131号</a > 
        <!-- <a href=" https://beian.miit.gov.cn" target="_blank"><img src="../assets/img/logo.jpg" style="width:14px;margin-right:4px">蜀ICP备2022026131号</a> -->
      </div>
    </div>
    <div class="message-content">
      <div class="message-info-box" v-if="showMessageBox">
        <h4 class="message-info-title">Uni Dating App</h4>
        <div class="message-tips-box">
          <div class="message-tips">
            Hi! Let us know how we can help and we’ll respond shortly.
          </div>
          <img class="message-tips-pop" src="../assets/img/pop.png" alt="">
        </div>
        
        <div class="message-inputs" v-if="!messageSuccess">
          <div class="message-inputbox">
            <div class="message-input-label" :class="{'message-input-label-highlight': !showMessageNamePlaceholder}">Name*</div>
              <input class="message-input" v-model="messageNameValue" @focus="messgeNameInputFocus()" @blur="messageNameInputblur(this)" type="text">
          </div>
          <div class="message-error-tips">{{showMessageNameError ? 'Please fill in this required field' : ''}}</div>
          
          <div class="message-inputbox" style="margin-bottom:0px;">
            <div class="message-input-label" :class="{'message-input-label-highlight': !showMessageEmailPlaceholder}">Email*</div>
            <input class="message-input" v-model="messageEmailValue" @focus="messageEmailInputFocus()" @blur="messageEmailInputblur(this)" type="text">
          </div>
          <div class="message-error-tips">{{showMessageEmailError ? 'Please enter a valid email address.' : ''}}</div>

          <textarea class="message-textarea" v-model="messageMessageValue" @blur="messageMessageInputblur()" placeholder="How can we help?*" ></textarea>
          <div class="message-error-tips">{{showMessageInfoError ? 'Please fill in this required field' : ''}}</div>

          <div class="message-write-btn-send" @click="messageSendClick()">SEND</div>
          <p class="message-left-gray" style="text-align: center;">This site is protected by reCAPTCHA and the Google <span class="message-left-red">Privacy Policy</span> and <span class="message-left-red">Terms of Service</span> apply.</p>
        </div>
        <img class="message-success-img" src="../assets/img/message-success.png" v-if="messageSuccess" alt="">
      </div>
      <div class="message-btn" :class="{'message-btn-gray': showMessageBox}" @click="showMessageBoxBtnClick()">
        <img class="message-btn-img" src="../assets/img/message.png" alt="" v-if="!showMessageBox">
        <img class="message-btn-img" src="../assets/img/cancel.png" alt="" v-else>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
   return {
      showInputPlaceholder: true,
      showNamePlaceholder: true,
      showEmailPlaceholder: true,
      showInputError: false,
      emailValue: '',
      contactNameValue: '',
      contactEmailValue: '',
      contactMessageValue: '',
      contactSuccess: false,
      showContactEmailError: false,
      subscribeSuccess: false,
      showContactWrite: false,
      showMessageBox: false,

      messageNameValue: '',
      messageEmailValue: '',
      messageMessageValue: '',
      showMessageNamePlaceholder: true,
      showMessageEmailPlaceholder: true,
      showMessageNameError: false,
      showMessageEmailError: false,
      showMessageInfoError: false,
      messageSuccess: false,
   }
  },
  methods: {
    isEmail: function (email) {
      var emailRegExp=/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;//验证邮箱的正则表达式
      var ok = emailRegExp.test(email);//验证是否符合要求
      return ok;
    },
    inputblur: function() {
      if (this.emailValue.length == 0) {
       this.showInputPlaceholder = true 
      } else {
        this.showInputError = !this.isEmail(this.emailValue)
      }
    },
    inputFocus: function() {
      this.showInputPlaceholder = false
    },
    contactNameInputFocus: function() {
      this.showNamePlaceholder = false
    },

    contactNameInputblur: function() {
      this.showNamePlaceholder = this.contactNameValue.length == 0
    },
    contactEmailInputFocus: function() {
      this.showEmailPlaceholder = false
    },

    contactEmailInputblur: function() {
      if (this.contactEmailValue.length == 0) {
       this.showEmailPlaceholder = true 
      } else {
        this.showContactEmailError = !this.isEmail(this.contactEmailValue)
      }
    },
    messgeNameInputFocus: function() {
      this.showMessageNamePlaceholder = false
    },
    messageNameInputblur: function() {
      if (this.messageNameValue.length == 0) {
       this.showMessageNamePlaceholder = true 
      }
      this.showMessageNameError = this.messageNameValue.length == 0
    },
    messageEmailInputFocus: function() {
      this.showMessageEmailPlaceholder = false
    },

    messageEmailInputblur: function() {
      if (this.messageEmailValue.length == 0) {
       this.showMessageEmailPlaceholder = true 
      } else {
        this.showMessageEmailError = !this.isEmail(this.messageEmailValue)
      }
    },
    messageMessageInputblur: function() {
      this.showMessageInfoError = this.messageMessageValue.length == 0
    },
    submitEmail: function() {
      if (this.emailValue.length > 0 && this.isEmail(this.emailValue)) {
        this.emailValue = ''
        this.showInputPlaceholder = true
        this.subscribeSuccess = true
      } else {
        this.showInputError = true
      }
    },
    showContactWriteClick: function() {
      this.showContactWrite = true
    },
    contactSendClick: function() {
      if (this.contactEmailValue.length > 0 && this.isEmail(this.contactEmailValue)) {
        this.showNamePlaceholder = true;
        this.showEmailPlaceholder = true;
        this.contactNameValue = ''
        this.contactEmailValue = ''
        this.contactMessageValue = ''
        this.showContactEmailError = false
        this.showContactWrite = false
        this.contactSuccess = true
      } else {
        this.showContactEmailError = true
      }
    },
    contactCancelClick: function() {
      this.showNamePlaceholder = true;
      this.showEmailPlaceholder = true;
      this.contactNameValue = ''
      this.contactEmailValue = ''
      this.contactMessageValue = ''
      this.showContactEmailError = false
      this.showContactWrite = false
    },
    showMessageBoxBtnClick: function() {
      this.showMessageBox = !this.showMessageBox
      if (!this.showMessageBox) {
        this.messageSuccess = false
      }
    },
    messageSendClick: function() {
      if (this.messageNameValue.length > 0 && this.messageMessageValue.length>0 && this.messageEmailValue.length>0 && this.isEmail(this.messageEmailValue)) {
        this.messageNameValue = ''
        this.messageEmailValue = ''
        this.messageMessageValue = ''
        this.showMessageNamePlaceholder = true
        this.showMessageEmailPlaceholder = true
        this.showMessageNameError = false
        this.showMessageEmailError = false
        this.showMessageInfoError = false
        this.messageSuccess = true
      }
      
    }
  }
}
</script>

<style scoped src="../assets/css/index.css">
</style>
